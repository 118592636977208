<template>
  <div class="temp">
    <B2cNavMenu :item-flag="1" :page="'business'" />
    <div class="center-box">
      <div class="main-box">
        <card v-for="(item,index) in modeList" :id="'m'+index" :key="'m'+index" class="imgCard" :src="item.src" />
        <!-- 用于一个占位 -->
        <div v-if="(modeList.length+1)%3 == 0" class="show-card" />
      </div>
    </div>
  </div>
</template>

<script>
// import BannerStation from '@/components/BannerStation'
import B2cNavMenu from '@/components/B2cNavMenu'
import card from '@/components/ImgCard'

export default {
  name: 'Temp',
  components: {
    // BannerStation,
    B2cNavMenu,
    card
  },
  data() {
    return {
      modeList: [{
        src: require('../../assets/img/model/oneModelPic.png')
      },
      {
        src: require('../../assets/img/model/twoModelPic.png')
      },
      {
        src: require('../../assets/img/model/threeModelPic.png')
      },
      {
        src: require('../../assets/img/model/fourModelPic.png')
      },
      {
        src: require('../../assets/img/model/fiveModelPic.png')
      },
      {
        src: require('../../assets/img/model/sixModelPic.png')
      },
      {
        src: require('../../assets/img/model/sevenModelPic.png')
      },
      {
        src: require('../../assets/img/model/eightModelPic.png')
      },
      // {
      //   src: require('../../assets/img/model/nineModelPic.png')
      // },
      {
        src: require('../../assets/img/model/tenModelPic.png')
      }
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.nav{
    margin-top: 68px;
    margin-bottom: 145px;
}
.center-box{
  display: flex;
  justify-content: center;
}
.main-box {
  padding: 0px 50px;
  margin-bottom: 50px;
  width: 1200px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  }
  .imgCard{
    margin-top: 50px;
  }
  .show-card{
    width: 300px;
  }

</style>
